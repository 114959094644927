<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface FormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
}
    import { FormInputData } from '~~/composables/useFormInput'
    import { useDebounceFn } from '@vueuse/core'
    import ValidateVatNumberEndpoint, { ValidatedVatNumberResponse } from '~~/app/common/endpoints/ValidateVatNumberEndpoint'
    const { $theme } = useNuxtApp();
    const emit = defineEmits(['validVatNumber']);
    const input = ref<HTMLInputElement | null>(null);
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean }
});
    const inputData: FormInputData = useFormInput(props);
    const endpoint = new ValidateVatNumberEndpoint();
    const response = ref<ValidatedVatNumberResponse | null>(null);
    const abortController = ref<AbortController | null>(null);
    const validatingVatNumber = ref(false);
    const onInputChange = async () => {
        if (!inputData.model.value) {
            validatingVatNumber.value = false;
            response.value = null;
            return;
        }
        validatingVatNumber.value = true;
        if (abortController.value) {
            console.log('Aborting previous search...');
            abortController.value.abort();
        }
        await validateVatNumber();
    };
    const validateVatNumber = useDebounceFn(async () => {
        if (!inputData.model.value) {
            return;
        }
        abortController.value = new AbortController();
        try {
            response.value = await endpoint.withAbortController(abortController.value).validate(inputData.model.value);
            if (response.value && response.value.data) {
                emit('validVatNumber', response.value);
            }
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Aborted');
            } else {
                console.error(e);
            }
        }
        validatingVatNumber.value = false;
    }, 200);
    const isValid = computed(() => {
        return !inputData.model.value ? true : response.value?.data;
    });
    defineExpose({
        isValid,
    });
    onMounted(async () => {
        validateVatNumber();
        if (props.autofocus && input.value) {
            console.log('input mounted', input.value, props.autofocus);
            await wait(250);
            input.value?.focus();
        }
    });
</script>

<template>
    <FormElement :inputData="inputData">
        <input
            v-model="inputData.model.value"
            :class="inputData.className.value"
            :name="inputData.props.inputName"
            :id="inputData.inputId.value"
            :placeholder="inputData.placeholder.value"
            :type="inputData.props.inputType"
            :disabled="inputData.props.disabled"
            @input="
                validatingVatNumber = true;
                onInputChange();
            "
            ref="input"
        />
        <template v-if="inputData.model.value">
            <template v-if="validatingVatNumber">
                <span class="text-[11px] text-gray-400">
                    <i class="fa-duotone fa-loader fa-spin"></i>
                    {{ $t('misc.vat_number_input.validating') }}
                </span>
            </template>
            <template v-if="!validatingVatNumber && response">
                <span
                    class="text-[11px] text-red-600"
                    v-if="response.error"
                >
                    <i class="fa-regular fa-xmark" />
                    {{ response.error.message }}
                </span>
                <span
                    class="text-[11px] text-green-600"
                    v-if="response.data"
                >
                    <i class="fa-regular fa-check" />
                    {{ $t('misc.vat_number_input.valid') }}
                </span>
            </template>
        </template>
    </FormElement>
</template>
